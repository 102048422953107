import Bugsnag from '@bugsnag/js'

const Singleton = (function () {
    let instance

    function createInstance(apiKey) {
        return Bugsnag.start({
            apiKey: '7da7ecefbef8bbdabfb6e1044af65aa5',
            appVersion: document.body.dataset.appVersion,
            collectUserIp: false,
            user: {id: document.body.dataset.userId},
            onError: function (event) {
                const allowedDomain = 'zvg24.net'
                const errorDomain = new URL(event.request.url).hostname

                return errorDomain === allowedDomain;
            }
        })
    }

    return {
        getInstance: function () {
            if (!instance) instance = createInstance()

            return instance
        }
    }
})()

export default Singleton
